import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    folsomImage: file(relativePath: { eq: "folsom.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keysImage: file(relativePath: { eq: "keys.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoWithRekeySetImage: file(relativePath: { eq: "leo-with-rekey-set.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function FolsomPage({ data }) {
  return (
    <MainLayout
      title="Folsom Locksmith Services | Low Rates, Fast & Affordable"
      description="Providing 24 hr residential, commercial, & auto locksmith services in Folsom, CA. Professional lock picking, rekey,  car key programming & more. 916-995-0135"
      offset
    >
      <ContactHeading background={data.folsomImage.childImageSharp.fluid}>
        <h1 className="text-center">Locksmith Folsom</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Folsom Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2 className="text-center">Folsom's Leading Locksmith</h2>
        <p className="indent">
          We've been providing residential, commercial, and automotive locksmith
          services in Folsom, CA and nearby areas for over 10 years now. The
          safety and convenience of our clients is our main goal. You can always
          expect to receive the highest level of professionalism from our team
          and the best product selections for any of your locksmith needs. Our
          locksmith technicians can install, replace, and repair a variety of
          locks. Our team works around the clock helping people like you in
          Folsom, CA so if you find yourself in need of locksmith services,
          please be sure to give us a ring.
        </p>
        <p className="indent mb-0">
          Swift Locksmith has been the trusted name for years in Folsom, CA and
          the surrounding area. Instead of letting any locksmith handle your
          keys, choose one that is well known, licensed and insured. We are your
          trusted name that you can always count on. We provide a wide scope of
          locksmith and security solutions for your home, vehicle and business.
          Thousands of car-owners, homeowners and businesses have put their
          confidence and trust in us for their safety and security.
        </p>
      </Container>
      <Container as="section" className="d-none d-sm-block">
        <Row>
          <Col>
            <Img
              fluid={data.locksmithWithCarImage.childImageSharp.fluid}
              alt="locksmith-with-car"
            />
          </Col>
          <Col>
            <Img fluid={data.keysImage.childImageSharp.fluid} alt="keys" />
          </Col>
          <Col>
            <Img
              fluid={data.leoWithRekeySetImage.childImageSharp.fluid}
              alt="leo-with-rekey-set"
            />
          </Col>
        </Row>
      </Container>
      <section>
        <div className="background-content bg-dark text-white">
          <Container>
            <h2>What We Offer</h2>
            <h3 className="text-primary">24 Hour Emergency Services</h3>
            <p>
              We strive to provide quick, dependable and efficient locksmith
              services for you. We offer 24 hour emergency mobile locksmith
              services in Folsom, CA so the next time you find yourself locked
              out of your home, lost your car keys, or locked inside your
              office, Call Swift Locksmith in Folsom and we will help you.
            </p>
            <h3 className="text-primary">Competitive Pricing</h3>
            <p>
              We appreciate all of our clients, whether you're a returning
              customer or a new customer, and aspire to surpass your
              expectations each time. We're constantly available, day or night
              and are only a phone call away. When it comes to value, we'll
              provide you a genuine answer about the price and an estimated
              arrival time. Leo and his team will provide you with a quote
              comprising of all fees with no hidden charges and we never change
              our prices after a project is finished.
            </p>
            <h3 className="text-primary">Outstanding Customer Service</h3>
            <p className="mb-0">
              We appreciate your business, and we make sure to let our customers
              know that. Whether you're locked out of your house and want help
              today, or you need install a new security system at your business,
              we will accommodate your schedule for your convenience and we are
              going to treat you with all the respect you deserve. We use the
              latest technologies and premium products to make sure the work is
              done properly the very first time, every time.
            </p>
          </Container>
        </div>
      </section>
      <Container as="section" className="mb-5">
        <h2>Call Swift Locksmiths in Folsom, CA Today</h2>
        <p className="indent">
          Let us look after you and protect your property. Call Swift Locksmith
          in Folsom, CA when you need a professional locksmith to get you back
          in your home, office or vehicle. Whether you are looking to get
          professional advice on upgrading your security system, need a
          duplicate key, or need to repair your locks, our knowledgeable staff
          will provide the right information and solutions to meet every
          locksmith need.
        </p>
        <p className="indent mb-0">
          We offer affordable and quick locksmith services to clients in Folsom,
          CA. Every one of our technicians is licensed, background checked and
          trained to ensure that we can meet all of your locksmith needs at the
          most competitive prices.
        </p>
      </Container>
    </MainLayout>
  )
}

export default FolsomPage
